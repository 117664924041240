import './style.css'
import Phaser from 'phaser'

const sizes = {
  width: 650,
  height: 1280
}
const speedDown = 100;

const gameStartDiv = document.querySelector("#gameStartDiv");
const gameEndDiv = document.querySelector("#gameEndDiv");
const startButton = document.querySelector("#startButton");
var userName = "";
var userId = "";


// get parameters from URL and save them into variables userName and userId
function getQueryParams() {
  const params = {};
  const queryString = window.location.search;  // Get the part of the URL after "?"

  console.log("Query string:", queryString);  // Debugging: Check what the query string looks like

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);  // Create URLSearchParams object from query string

    // Iterate over each parameter and store it in the params object
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
      console.log(`Parameter found - ${key}: ${value}`);  // Debugging: Log each key-value pair
    }
  } else {
    console.log("No query parameters found.");
  }
  userId = params["id"]
  userName = params["name"] + " " + params["last_name"]
  return params;
}


class BasicButton extends Phaser.GameObjects.Sprite {
  constructor(config) {

    //check if config contains a scene
    if (!config.scene) {
      console.log('missing scene');
      return;
    }
    //check if config contains a key
    if (!config.key) {
      console.log("missing key!");
      return;
    }
    //if there is no up property assume 0
    if (!config.up) {
      config.up = 0;
    }
    //if there is no down in config use up
    if (!config.down) {
      config.down = config.up;
    }
    //if there is no over in config use up
    if (!config.over) {
      config.over = config.up;
    }
    //call the constructor of the parent
    //set at 0,0 in case there is no x and y
    //in the config
    super(config.scene, 0, 0, config.key, config.up);

    //make a class level reference to the config
    this.config = config;


    //if there is an x assign it
    if (config.x) {
      this.x = config.x;
    }
    //if there is an x assign it
    if (config.y) {
      this.y = config.y;
    }

    if (config.width && config.height) {
      this.setDisplaySize(config.width, config.height);
    }
    //
    //add this to the scene
    config.scene.add.existing(this);
    //
    //make interactive and set listeners
    this.setInteractive();
    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onUp, this);
  }
  onDown() {
    this.setFrame(this.config.down);
  }
  onOver() {
    this.setFrame(this.config.over);
  }
  onUp() {
    this.setFrame(this.config.up);
  }
}

class GameScene extends Phaser.Scene {

  constructor() {
    super("scene-game");
    this.player
    this.playerPressed
    this.cursor
    this.playerSpeed = speedDown + 50
    this.points = 0;
    this.textScore;
    this.textTime;
    this.username;
    this.dropSound;
    this.emitter;
    this.button;
    this.buttonBack;
    this.bitcoin;
    this.bitcoinLite;
    this.dogPressed;

    this.lightEffect;
    this.navigationHome;
    this.navigationShare;
    this.navigationGroups;

  }
  preload() {

    getQueryParams();
    this.load.image("back", "/assets/back.png");
    this.load.image("test", "/assets/dog.png");
    this.load.image("ball", "/assets/ball.png");
    this.load.image("bitcoin", "/assets/bitcoin.png");
    this.load.image("backbutton", "/assets/buttonback.png");
    this.load.image("dogPressed", "/assets/dogpressed.png");
    this.load.image("homenav", "/assets/homenav.png");
    this.load.image("sharenav", "/assets/sharenav.png");
    this.load.image("groupsnav", "/assets/groupsnav.png");
    this.load.image("lightEffect", "/assets/lighteffect.png");
    this.load.image("bitcoinLite", "/assets/coinlight.png");
    this.load.audio("drop", "/assets/sounds/drop.wav");
  }

  create() {
    this.dropSound = this.sound.add("drop");
    this.add.image(0, 0, "back").setDisplaySize(sizes.width * 2, sizes.height * 2);
    this.player = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 200, "test").setOrigin(0, 0);
    this.player.setInteractive();
    this.player.on('pointerdown', this.onDown, this);
    this.player.on('pointerup', this.onUp, this);

    this.player.setDisplaySize(400, 400).setOffset(0, 0);
    this.player.setImmovable = true;
    this.player.body.allowGravity = false;
    this.player.setCollideWorldBounds(true);

    this.playerPressed = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 200, "dogPressed").setOrigin(0, 0);
    this.playerPressed.setInteractive();
    this.playerPressed.on('pointerdown', this.onDown, this);
    this.playerPressed.on('pointerup', this.onUp, this);
    this.playerPressed.removeFromDisplayList();

    this.playerPressed.setDisplaySize(400, 400).setOffset(0, 0);
    this.playerPressed.setImmovable = true;
    this.playerPressed.body.allowGravity = false;
    this.playerPressed.setCollideWorldBounds(true);

    this.cursor = this.input.keyboard.createCursorKeys();

    this.buttonBack = this.add.image(sizes.width / 2, 110, "backbutton").setDisplaySize(300, 60);
    this.bitcoin = this.physics.add.image(this.buttonBack.x - 120, this.buttonBack.y, "bitcoin").setDisplaySize(40, 40);
    this.bitcoin.setImmovable = true;
    this.bitcoin.body.allowGravity = false;

    //bottom nav buttons
    this.navigationHome = this.add.image(0 + 30, sizes.height - 90, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationHome.setInteractive();
    this.navigationHome.on('pointerdown', this.onDown, this);
    this.navigationHome.on('pointerup', this.onUp, this);

    this.navigationGroups = this.add.image(sizes.width / 2 - 20, sizes.height - 90, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationGroups.setInteractive();
    this.navigationGroups.on('pointerdown', this.onDown, this);
    this.navigationGroups.on('pointerup', this.onUp, this);

    this.navigationShare = this.add.image(sizes.width - 100, sizes.height - 90, "sharenav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationShare.setInteractive();
    this.navigationShare.on('pointerdown', this.onDown, this);
    this.navigationShare.on('pointerup', this.onUp, this);

    //light effect
    this.lightEffect = this.add.image(0, 40, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);
    this.lightEffect = this.add.image(0, sizes.height - 130, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);

    // Score text
    this.textScore = this.add.text(sizes.width / 2, 80, this.points, { font: "60px arial bold", fill: "#ffffff" });


    // userName text
    this.username = this.add.text(10, 10, userName, { font: "25px arial bold", fill: "#ffffff" });


    this.emitter = this.add.particles(100, 100, "bitcoinLite",
      {
        speed: 300,
        gravity: speedDown - 500,
        scale: { start: 0.9, end: 0 }, end: 0,
        duration: 300,
        lifespan: 1000,
        emitting: true,
        follow: this.player,

      })
    this.emitter.setPosition(this.player.x, this.player.y - 280);

    window.Telegram.WebApp.expand()
    /*     BUTTON EXAMPLE
  this.button = new BasicButton({
    'scene': this,
    'key': 'bitcoin',
    'up': 0,
    'over': 0,
    'down': 0,
    'x': sizes.width / 4 - 20,
    'y': sizes.height - sizes.height / 9,
    'width': 100,
    'height': 100
  });
  */

  }
  update() {

    const { left, right } = this.cursor
    if (left.isDown) {
      this.player.setVelocityX(-this.playerSpeed - 50);
    }
    else if (right.isDown) {
      this.player.setVelocityX(this.playerSpeed + 50);
    }
    else {
      this.player.setVelocityX(0);
    }
    this.textScore.setText(this.points);

  }


  onDown(sprite, pointer) {
    this.points = this.points + 10;

    this.player.removeFromDisplayList();
    this.playerPressed.addToDisplayList();
    this.emitter.start();

  }
  onUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  targetHit() {
    this.enemy.x = this.getRandomX();
    this.enemy.y = 0;
    this.points++;
    console.log(this.points);
    this.textScore.setText(this.points);
    this.dropSound.play();
    this.emitter.start();
  }

  getRandomX() {
    return Math.floor(Math.random() * sizes.width);
  }

  gameOver() {
    console.log("Game over!");
  }
}

const Config = {


  type: Phaser.WEBGL,
  width: sizes.width,
  height: sizes.height,
  scale: {
    // Fit to window
    mode: Phaser.Scale.FIT,
    // Center vertically and horizontally
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  canvas: gameCanvas,
  physics: {
    default: "arcade",
    arcade: {
      gravity: {
        y: speedDown
      },
      debug: false
    }
  },
  scene: [GameScene]
}

const game = new Phaser.Game(Config);

startButton.addEventListener("click", () => {
  gameStartDiv.style.display = "none"
  game.scene.resume("scene-game")
})


